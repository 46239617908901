<template>
  <div>
    <!-- select 2 demo -->

    <div>
      <!-- Table Top -->
      <div>
        <b-sidebar
          id="sidebar-right"
          ref="mySidebar"
          bg-variant="white"
          v-model="visibility"
          sidebar-class="sidebar-lg"
          right
          no-header
          backdrop
          shadow
        >
          <div
            class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
          >
            <h5 class="mb-0">
              {{ sidebarTitle }}
            </h5>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hideSideBar()"
            />
          </div>
          <b-form class="p-2" @submit.prevent>
            <b-row class="mt-1">
              <!-- <b-col md="12">
                <b-form-group
                  label="Account Head*"
                  invalid-feedback="Account head is required."
                  ref="account"
                >
                  <v-select
                    ref="account"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="accountOptions"
                    :reduce="(opt) => opt.id"
                    label="account"
                    v-model="myObj.account"
                    placeholder="Select account head"
                    @change="CheckAccount()"
                  />
                </b-form-group>
              </b-col> -->

              <b-col md="12">
                <b-form-group
                  label="Amount *"
                  invalid-feedback="Amount is required."
                  ref="balance"
                >
                  <b-form-input
                    id="mc-first-name"
                    placeholder="Enter amount here"
                    v-validate="'required'"
                    ref="balance"
                    v-model="myObj.balance"
                  />
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="success"
                  class="mr-1 fullWidth"
                  @click="Add()"
                  :disabled="request"
                  block
                >
                  <b-spinner v-if="request" small type="grow" />
                  <span v-if="request == false"> {{ sidebarButton }} </span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
          <sidebar-content />
        </b-sidebar>

        <b-card>
          <b-row class="mt-1">
            <b-col xl="2" lg="3" md="4" sm="12" cols="12">
              <b-dropdown
                style="margin-inline: 2px; margin-bottom: 2px"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                split
                variant="primary"
                text="Session"
                right
              >
                <b-dropdown-item> 1 </b-dropdown-item>
                <b-dropdown-item> 2 </b-dropdown-item>

                <b-dropdown-divider />
              </b-dropdown>
            </b-col>

            <b-col
              class="mt-lg-0 mt-md-0 mt-sm-1 mt-1"
              xl="10"
              lg="9"
              md="8"
              sm="12"
              cols="12"
            >
              <b-form-group class="">
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>

                  <b-form-input v-model="searchQuery" placeholder="Search...">
                  </b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>

          <b-table
            class="mt-1"
            ref="selectableTable"
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :busy="dataLoading"
            responsive
            hover
          >
            <template #table-busy>
              <div
                class="d-flex justify-content-center mb-3"
                style="margin-top: 50px"
              >
                <b-spinner
                  style="width: 3rem; height: 3rem"
                  type="grow"
                  variant="primary"
                  label="Spinning"
                ></b-spinner>
              </div>
            </template>
            <template #cell(name)="data">
              <b-badge style="margin-inline: 2px" variant="light-primary">
                {{ data.item.name }}
              </b-badge>
            </template>

            <template #head(actions)="data">
              <div class="mr-5 text-right">
                <span>{{ data.label }}</span>
              </div>
            </template>

            <template #cell(actions)="data">
              <div class="text-right">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                  @click="Edit(data.item)"
                >
                  <feather-icon icon="EditIcon" class="" />
                </b-button>

                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger"
                  class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                  @click="Delete(data.item.id)"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </div>
            </template>
          </b-table>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, between } from "vuelidate/lib/validators";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BInputGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import store from "@/store";
import { avatarText } from "@core/utils/filter";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import { forEach } from "postcss-rtl/lib/affected-props";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    //Multiselect,
    BCard,
    BRow,
    BBadge,
    BTable,
    BCol,
    BFormInput,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    // BFormCheckbox,
    BForm,
    BPagination,
    BSpinner,
    BDropdown,
    BDropdownItem,
    vSelect,
    flatPickr,
  },
  props: {},
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  async created() {
    // this.LoadData();
  },
  computed: {
    ...mapGetters({ cmbDepartment: "cmbDepartment" }),
    ...mapGetters({ data: "data" }),
    getColor(ind) {
      console.log("%%%%%% getting color", ind.index);
      return this.color[ind];
    },

    PageChange() {
      console.log(this.currentPage);
      return this.LoadData();
    },
    filters: function () {
      return this.items.filter((pro) => {
        return pro.details.toLowerCase().match(this.searchQuery.toLowerCase());
      });
    },
  },
  data() {
    return {
      myDepartments: [],
      request: false,
      colorDep: [],
      visibility1: false,
      color: [
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
      ],

      sidebarTitle: "Add Balance",
      sidebarButton: "Save",
      buttonIcon: "PlusIcon",
      dataLoading: false,

      visibility: false,
      logoloading: "",
      searchQuery: "",
      currentPage: 1,
      perPage: 30,
      totalRows: 0,

      errors: {
        status: false,
      },

      index: null,

      fields: [
        { label: "account", key: "account" },
        { label: "opening balance", key: "balance" },
        { key: "actions", label: "actions" },
      ],
      items: [
        // {
        //   id: 9,
        //   account: 90,
        //   balance: 45000,
        //   campusID: this.$store.state.userData.cId,
        // },
      ],
      accountOptions: [
        // {
        //   id: 1,
        //   code: 999,
        // },
      ],
      selected: null,
      rangeDate: null,
      myObj: {
        id: 0,
        account: 0,
        balance: 0,
        campusID: this.$store.state.userData.cId,
      },
      depObj: {
        id: 0,
        name: "",
        campusID: this.$store.state.userData.cId,
      },
      detailObj: {},
      timeout: 10000,
    };
  },
  validations: {
    depObj: {
      name: {
        required,
        minLength: minLength(4),
      },
    },
    age: {
      between: between(20, 30),
    },
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    async Edit(item) {
      //   var obj = {
      //     url:
      //       this.$store.state.domain +
      //       "examtypes/GetSelected?id=" +
      //       id +
      //       "&db=" +
      //       this.$store.state.userData.db,
      //     token: this.$store.state.userData.token,
      //   };
      //   this.myObj = await this.get(obj);
      //   console.log("editObj:", this.myObj);
      this.myObj = item;
      this.visibility = true;
      this.sidebarTitle = "Edit balance";
      this.sidebarButton = "Update";
      var elem = this.$refs["account"];
      elem.state = undefined;
      var elem = this.$refs["balance"];
      elem.state = undefined;
    },

    AddOpen() {
      this.myObj = {
        id: 0,
        account: 0,
        balance: 0,
        campusID: this.$store.state.userData.cId,
      };
      this.visibility = true;
      this.sidebarTitle = "Add balance";
      this.sidebarButton = "Save";
      var elem = this.$refs["account"];
      elem.state = undefined;
      var elem = this.$refs["balance"];
      elem.state = undefined;
    },

    CheckAccount() {
      var elem = this.$refs["account"];
      if (this.myObj.account == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckBalance() {
      var elem = this.$refs["balance"];
      var re = /^[0-9]+$/;
      if (re.test(this.myObj.balance) && this.myObj.balance !== 0) {
        this.myObj.balance = parseInt(this.myObj.balance);
        return (elem.state = true);
      } else {
        return (elem.state = false);
      }
    },

    hideSideBar() {
      this.visibility = false;
    },

    async LoadData() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "OpeningBalance?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.items = await this.get(obj);
      if (this.items.length != 0) {
        this.dataLoading = false;
        this.totalRows = this.items.length;
      }
      console.log("bal", this.items);
    },

    async Add() {
      //   this.CheckAccount();
      this.CheckBalance();
      if (this.CheckBalance() == false) {
        return this.$bvToast.toast("Please enter the amount.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        if (this.myObj.id == 0) {
          //Add
          this.request = true;
          console.log("obj:", this.myObj);
          this.request = false;
          this.visibility = false;
          //   var status = await this.post({
          //     url:
          //       this.$store.state.domain +
          //       "OpeningBalance?db=" +
          //       this.$store.state.userData.db,
          //     body: this.myObj,
          //     message: "Opening Balance added successfully.",
          //     context: this,
          //     token: this.$store.state.userData.token,
          //   });
          //   this.request = false;
          //   this.visibility = false;
          //   if (status) this.LoadData();
        } else {
          //Edit
          this.request = true;
          console.log("edit_obj:", this.myObj);
          this.request = false;
          this.visibility = false;
          //   var status = await this.put({
          //     url:
          //       this.$store.state.domain +
          //       "OpeningBalance/" +
          //       this.myObj.id +
          //       "?db=" +
          //       this.$store.state.userData.db,
          //     body: this.myObj,
          //     message: "Opening Balance updated successfully.",
          //     context: this,
          //     token: this.$store.state.userData.token,
          //   });
          //   this.request = false;
          //   this.visibility = false;
          //   console.log("status", status);
          //   if (status) this.LoadData();
        }
      }
    },

    async Delete(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });
      if (result.isConfirmed) {
        // var status = await this.delete({
        //   url:
        //     this.$store.state.domain +
        //     "OpeningBalance/" +
        //     id +
        //     "?db=" +
        //     this.$store.state.userData.db,
        //   body: null,
        //   message: "Opening Balance removed successfully.",
        //   context: this,
        // });
        // if (status) this.LoadData();
        console.log("balance deleted");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
</style>
